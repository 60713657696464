// import axios from "axios";

let lsSinyalMexc1InchBsc = JSON.parse(localStorage.getItem("mw_sinyal_mexc_1inch_bsc"));

const state = {
  sinyal: lsSinyalMexc1InchBsc != null ? lsSinyalMexc1InchBsc.sinyal : [],
  lastFetch: lsSinyalMexc1InchBsc != null ? lsSinyalMexc1InchBsc.lastFetch : null,
};

const getters = {
  listSinyalMexc1InchBsc(state) {
    return state.sinyal;
  },
  lastFetchSinyalMexc1InchBsc(state) {
    return state.lastFetch != null ? new Date(state.lastFetch).toLocaleString("id-ID") : "";
  },
};

const actions = {
  registerSinyalMexc1InchBsc(context) {
    let sinyal = [];
    console.log("sinyal", sinyal);
    context.rootState.coin.coins_mexc_bsc.forEach(item => {
      let askPrice = context.rootGetters.getMexcBookCoinPriceAsk(item.nama_coin);
      let bidPrice = context.rootGetters.getMexcBookCoinPriceBid(item.nama_coin);
      let mdl_kiri = context.rootState.setting.settings.modal_kiri_mexc_1inch_bsc || 400;
      if (askPrice.qty * askPrice.price < mdl_kiri) {
        mdl_kiri = askPrice.qty * askPrice.price;
      }
      let mdl_kanan = context.rootState.setting.settings.modal_kanan_mexc_1inch_bsc || 400;
      if (bidPrice.qty * bidPrice.price < mdl_kanan) {
        mdl_kanan = bidPrice.qty * bidPrice.price;
      }
      sinyal.push({
        symbol: item.nama_coin,
        pair: askPrice.pair,
        modal_kiri_mexc_1inch_bsc: mdl_kiri,
        modal_kanan_mexc_1inch_bsc: mdl_kanan,
        coin_address: item.coin_address,
        mexc_ask_price: askPrice.price,
        ask_xprice: askPrice.xprice !== undefined ? askPrice.xprice : null,
        mexc_bid_price: bidPrice.price,
        bid_xprice: bidPrice.xprice !== undefined ? bidPrice.xprice : null,
        inch_usdt: 0,
        selisih_pnl_kiri_ori: 0,
        selisih_pnl_kiri: 0,
        selisih_pnl_kiri_persen: 0,
        inch_sum: 0,
        inch_price: 0,
        selisih_pnl_kanan_ori: 0,
        selisih_pnl_kanan: 0,
        selisih_pnl_kanan_persen: 0,
        aktif: item.aktif_1inch != undefined ? item.aktif_1inch : true,
        offset_pnl: item.offset_pnl != undefined ? item.offset_pnl : 0,
        status_wd: false,
        status_depo: false,
        fee_wd: 0,
      });
    });
    console.log(sinyal);
    context.commit("registerSinyalMexc1InchBsc", sinyal);
  },
  updateSinyalMexc1InchBsc(context, coin) {
    let sinyal = state.sinyal;
    let index = state.sinyal.findIndex(item => {
      return item.symbol == coin.symbol;
    });
    let symbol = sinyal[index];
    symbol = { ...symbol, ...coin.value };
    sinyal.splice(index, 1, symbol);
    context.commit("registerSinyalMexc1InchBsc", sinyal);
  },
  clearSinyalMexc1InchBsc(context) {
    let sinyal = state.sinyal.map(item => {
      return {
        ...item,
        mexc_ask_price: "wait...",
        mexc_bid_price: "wait...",
        inch_usdt: "wait...",
        selisih_pnl_kiri: "wait...",
        selisih_pnl_kiri_persen: "wait...",
        inch_sum: "wait...",
        inch_price: "wait...",
        selisih_pnl_kanan: "wait...",
        selisih_pnl_kanan_persen: "wait...",
      };
    });
    // sinyal.filter(item => {{
    // }});
    context.commit("registerSinyalMexc1InchBsc", sinyal);
  },
};

const mutations = {
  registerSinyalMexc1InchBsc(state, payload) {
    localStorage.setItem("mw_sinyal_mexc_1inch_bsc", JSON.stringify({ sinyal: payload, lastFetch: Date.now() }));
    state.sinyal = payload;
    state.lastFetch = Date.now();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
