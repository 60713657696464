import axios from "axios";

let lsHotbit = JSON.parse(localStorage.getItem("mw_hotbit"));

const state = {
  bookTicker: lsHotbit != null && lsHotbit.ticker != null ? lsHotbit.ticker : [],
  lastFetch: lsHotbit != null && lsHotbit.date != null ? lsHotbit.date : null,
};

const getters = {
  listHotbitBookTicker(state) {
    return state.bookTicker;
  },
  getHotbitBookCoinPriceAsk: state => coin => {
    let prices = state.bookTicker.filter(
      item =>
        item.symbol == coin + "_USDT" || item.symbol == coin + "_BUSD" || item.symbol == coin + "_nUSD" || item.symbol == coin + "_BNB" || item.symbol == coin + "_BTC" || item.symbol == coin + "_ETH",
    );
    let price;
    price = prices.find(item => item.symbol == coin + "_USDT");
    if (price != undefined && price.sell != 0) {
      return { price: price.sell, pair: "USDT" };
    }
    price = prices.find(item => item.symbol == coin + "_BUSD");
    if (price != undefined && price.sell != 0) {
      return { price: price.sell, pair: "BUSD" };
    }
    price = prices.find(item => item.symbol == coin + "_nUSD");
    if (price != undefined && price.sell != 0) {
      return { price: parseFloat(price.sell) * 0.000000001, pair: "nUSD" };
    }
    price = prices.find(item => item.symbol == coin + "_BNB");
    if (price != undefined && price.sell != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BNB_USDT");
      return { price: price.sell * multiply.sell, pair: "BNB", xprice: price.sell, pairPrice: multiply.sell };
    }
    price = prices.find(item => item.symbol == coin + "_BTC");
    if (price != undefined && price.sell != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BTC_USDT");
      return { price: price.sell * multiply.sell, pair: "BTC", xprice: price.sell, pairPrice: multiply.sell };
    }
    price = prices.find(item => item.symbol == coin + "_ETH");
    if (price != undefined && price.sell != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "ETH_USDT");
      return { price: price.sell * multiply.sell, pair: "ETH", xprice: price.sell, pairPrice: multiply.sell };
    }
    return { price: 0, pair: "NONE" };
  },
  getHotbitBookCoinPriceBid: state => coin => {
    let prices = state.bookTicker.filter(
      item =>
        item.symbol == coin + "_USDT" || item.symbol == coin + "_BUSD" || item.symbol == coin + "_nUSD" || item.symbol == coin + "_BNB" || item.symbol == coin + "_BTC" || item.symbol == coin + "_ETH",
    );
    let price;
    price = prices.find(item => item.symbol == coin + "_USDT");
    if (price != undefined && price.buy != 0) {
      return { price: price.buy, pair: "USDT" };
    }
    price = prices.find(item => item.symbol == coin + "_BUSD");
    if (price != undefined && price.buy != 0) {
      return { price: price.buy, pair: "BUSD" };
    }
    price = prices.find(item => item.symbol == coin + "_nUSD");
    if (price != undefined && price.buy != 0) {
      return { price: parseFloat(price.buy) * 0.000000001, pair: "nUSD" };
    }
    price = prices.find(item => item.symbol == coin + "_BNB");
    if (price != undefined && price.buy != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BNB_USDT");
      return { price: price.buy * multiply.buy, pair: "BNB", xprice: price.buy, pairPrice: multiply.buy };
    }
    price = prices.find(item => item.symbol == coin + "_BTC");
    if (price != undefined && price.buy != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BTC_USDT");
      return { price: price.buy * multiply.buy, pair: "BTC", xprice: price.buy, pairPrice: multiply.buy };
    }
    price = prices.find(item => item.symbol == coin + "_ETH");
    if (price != undefined && price.buy != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "ETH_USDT");
      return { price: price.buy * multiply.buy, pair: "ETH", xprice: price.buy, pairPrice: multiply.buy };
    }
    return { price: 0, pair: "NONE" };
  },
};

const actions = {
  updateHotbitBookTicker(context) {
    return axios
      .get("https://api.hotbit.io/api/v1/allticker")
      .then(response => {
        let bookTicker = response.data.ticker;
        console.log("bt", bookTicker);
        if (bookTicker[0] != undefined && bookTicker[0].symbol != undefined) {
          localStorage.setItem("mw_hotbit", JSON.stringify({ bookTicker, lastFetch: Date.now() }));
          context.commit("updateHotbitBookTicker", bookTicker);
          return {
            status: "ok",
          };
        } else {
          return {
            status: "error",
            message: "Format respon hotbit tidak valid",
          };
        }
      })
      .catch(error => {
        if (error.response) {
          return {
            status: "error",
            message: error.response.status,
          };
        } else if (error.request) {
          return {
            status: "error",
            message: "Tidak bisa menghubungi hotbit",
          };
        } else {
          return {
            status: "error",
            message: error.message,
          };
        }
      });
  },
};

const mutations = {
  updateHotbitBookTicker(state, payload) {
    state.bookTicker = payload;
    state.lastFetch = Date.now();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
