// import axios from "axios";

let lsSinyalHuobiDodoHeco = JSON.parse(localStorage.getItem("mw_sinyal_huobi_dodo_heco"));

const state = {
  sinyal: lsSinyalHuobiDodoHeco != null ? lsSinyalHuobiDodoHeco.sinyal : [],
  lastFetch: lsSinyalHuobiDodoHeco != null ? lsSinyalHuobiDodoHeco.lastFetch : null,
};

const getters = {
  listSinyalHuobiDodoHeco(state) {
    return state.sinyal;
  },
  lastFetchSinyalHuobiDodoHeco(state) {
    return state.lastFetch != null ? new Date(state.lastFetch).toLocaleString("id-ID") : "";
  },
};

const actions = {
  registerSinyalHuobiDodoHeco(context) {
    let sinyal = [];
    console.log("sinyal", sinyal);
    context.rootState.coin.coins_huobi_heco.forEach(item => {
      let askPrice = context.rootGetters.getHuobiBookCoinPriceAsk(item.nama_coin);
      let bidPrice = context.rootGetters.getHuobiBookCoinPriceBid(item.nama_coin);
      let mdl_kiri = context.rootState.setting.settings.modal_kiri_huobi_dodo_heco || 400;
      if (askPrice.volume * askPrice.price < mdl_kiri) {
        mdl_kiri = context.rootState.setting.settings.modal_kiri_huobi_dodo_heco_min || 50;
      }
      let mdl_kanan = context.rootState.setting.settings.modal_kanan_huobi_dodo_heco || 400;
      if (askPrice.volume * bidPrice.price < mdl_kanan) {
        mdl_kanan = context.rootState.setting.settings.modal_kanan_huobi_dodo_heco_min || 11;
      }
      sinyal.push({
        symbol: item.nama_coin,
        pair: askPrice.pair,
        modal_kiri_huobi_dodo_heco: mdl_kiri,
        modal_kanan_huobi_dodo_heco: mdl_kanan,
        coin_address: item.coin_address,
        huobi_ask_price: askPrice.price,
        ask_xprice: askPrice.xprice !== undefined ? askPrice.xprice : null,
        huobi_bid_price: bidPrice.price,
        bid_xprice: bidPrice.xprice !== undefined ? bidPrice.xprice : null,
        inch_usdt: 0,
        selisih_pnl_kiri: 0,
        selisih_pnl_kiri_persen: 0,
        inch_sum: 0,
        inch_price: 0,
        selisih_pnl_kanan: 0,
        selisih_pnl_kanan_persen: 0,
        aktif: item.aktif_dodo != undefined ? item.aktif_dodo : true,
        offset_pnl: item.offset_pnl != undefined ? item.offset_pnl : 0,
        status_wd: false,
        status_depo: false,
        fee_wd: 0,
      });
    });
    console.log(sinyal);
    context.commit("registerSinyalHuobiDodoHeco", sinyal);
  },
  updateSinyalHuobiDodoHeco(context, coin) {
    let sinyal = state.sinyal;
    let index = state.sinyal.findIndex(item => {
      return item.symbol == coin.symbol;
    });
    let symbol = sinyal[index];
    symbol = { ...symbol, ...coin.value };
    sinyal.splice(index, 1, symbol);
    context.commit("registerSinyalHuobiDodoHeco", sinyal);
  },
  clearSinyalHuobiDodoHeco(context) {
    let sinyal = state.sinyal.map(item => {
      return {
        ...item,
        huobi_ask_price: "wait...",
        huobi_bid_price: "wait...",
        inch_usdt: "wait...",
        selisih_pnl_kiri: "wait...",
        selisih_pnl_kiri_persen: "wait...",
        inch_sum: "wait...",
        inch_price: "wait...",
        selisih_pnl_kanan: "wait...",
        selisih_pnl_kanan_persen: "wait...",
      };
    });
    // sinyal.filter(item => {{
    // }});
    context.commit("registerSinyalHuobiDodoHeco", sinyal);
  },
};

const mutations = {
  registerSinyalHuobiDodoHeco(state, payload) {
    localStorage.setItem("mw_sinyal_huobi_dodo_heco", JSON.stringify({ sinyal: payload, lastFetch: Date.now() }));
    state.sinyal = payload;
    state.lastFetch = Date.now();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
