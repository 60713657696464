// import axios from "axios";

let lsSinyalKucoinMultiErc = JSON.parse(localStorage.getItem("mw_sinyal_kucoin_multi_erc"));

const state = {
  sinyal: lsSinyalKucoinMultiErc != null ? lsSinyalKucoinMultiErc.sinyal : [],
  lastFetch: lsSinyalKucoinMultiErc != null ? lsSinyalKucoinMultiErc.lastFetch : null,
};

const getters = {
  listSinyalKucoinMultiErc(state) {
    return state.sinyal;
  },
  lastFetchSinyalKucoinMultiErc(state) {
    return state.lastFetch != null ? new Date(state.lastFetch).toLocaleString("id-ID") : "";
  },
};

const actions = {
  registerSinyalKucoinMultiErc(context) {
    let sinyal = [];
    context.rootState.coin.coins_kucoin_erc.forEach(item => {
      let askPrice = context.rootGetters.getKucoinBookCoinPriceAsk(item.nama_coin);
      let bidPrice = context.rootGetters.getKucoinBookCoinPriceBid(item.nama_coin);
      sinyal.push({
        symbol: item.nama_coin,
        pair: askPrice.pair,
        modal_kiri_kucoin_multi_erc: context.rootState.setting.settings.modal_kiri_kucoin_multi_erc || 400,
        modal_kanan_kucoin_multi_erc: context.rootState.setting.settings.modal_kanan_kucoin_multi_erc || 400,
        coin_address: item.coin_address,
        kucoin_ask_price: askPrice.price,
        ask_xprice: askPrice.xprice !== undefined ? askPrice.xprice : null,
        kucoin_bid_price: bidPrice.price,
        bid_xprice: bidPrice.xprice !== undefined ? bidPrice.xprice : null,
        inch_usdt: 0,
        matcha_usdt: 0,
        selisih_pnl_kiri: 0,
        selisih_pnl_kiri_matcha: 0,
        selisih_pnl_kiri_persen: 0,
        selisih_pnl_kiri_persen_matcha: 0,
        inch_sum: 0,
        matcha_sum: 0,
        inch_price: 0,
        matcha_price: 0,
        selisih_pnl_kanan: 0,
        selisih_pnl_kanan_matcha: 0,
        selisih_pnl_kanan_persen: 0,
        selisih_pnl_kanan_persen_matcha: 0,
        aktif: item.aktif_multi != undefined ? item.aktif_multi : true,
        offset_pnl: item.offset_pnl != undefined ? item.offset_pnl : 0,
        status_wd: false,
        status_depo: false,
        fee_wd: 0,
      });
    });
    context.commit("registerSinyalKucoinMultiErc", sinyal);
  },
  updateSinyalKucoinMultiErc(context, coin) {
    let sinyal = state.sinyal;
    let index = state.sinyal.findIndex(item => {
      return item.symbol == coin.symbol;
    });
    let symbol = sinyal[index];
    symbol = { ...symbol, ...coin.value };
    sinyal.splice(index, 1, symbol);
    context.commit("registerSinyalKucoinMultiErc", sinyal);
  },
  clearSinyalKucoinMultiErc(context) {
    let sinyal = state.sinyal.map(item => {
      return {
        ...item,
        kucoin_ask_price: "wait...",
        kucoin_bid_price: "wait...",
        inch_usdt: "wait...",
        matcha_usdt: "wait...",
        selisih_pnl_kiri: "wait...",
        selisih_pnl_kiri_matcha: "wait...",
        selisih_pnl_kiri_persen: "wait...",
        selisih_pnl_kiri_persen_matcha: "wait...",
        inch_sum: "wait...",
        matcha_sum: "wait...",
        inch_price: "wait...",
        matcha_price: "wait...",
        selisih_pnl_kanan: "wait...",
        selisih_pnl_kanan_matcha: "wait...",
        selisih_pnl_kanan_persen: "wait...",
        selisih_pnl_kanan_persen_matcha: "wait...",
      };
    });
    // sinyal.filter(item => {{
    // }});
    context.commit("registerSinyalKucoinMultiErc", sinyal);
  },
};

const mutations = {
  registerSinyalKucoinMultiErc(state, payload) {
    localStorage.setItem("mw_sinyal_kucoin_multi_erc", JSON.stringify({ sinyal: payload, lastFetch: Date.now() }));
    state.sinyal = payload;
    state.lastFetch = Date.now();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
