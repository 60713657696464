import axios from "axios";

let lsFtx = JSON.parse(localStorage.getItem("mw_ftx"));

const state = {
  bookTicker: lsFtx != null && lsFtx.bookTicker != null ? lsFtx.bookTicker : [],
  lastFetch: lsFtx != null && lsFtx.lastFetch != null ? lsFtx.lastFetch : null,
};

const getters = {
  listFtxBookTicker(state) {
    return state.bookTicker;
  },
  getFtxBookCoinPriceAsk: state => coin => {
    let prices = state.bookTicker.filter(
      item => item.symbol == coin + "USD" || item.symbol == coin + "USDT" || item.symbol == coin + "BUSD" || item.symbol == coin + "BNB" || item.symbol == coin + "BTC" || item.symbol == coin + "ETH",
    );
    let price;
    price = prices.find(item => item.symbol == coin + "USD");
    if (price != undefined && price.askPrice != 0) {
      return { price: price.askPrice, pair: "USD", volume: price.askSize * price.askPrice };
    }
    price = prices.find(item => item.symbol == coin + "USDT");
    if (price != undefined && price.askPrice != 0) {
      return { price: price.askPrice, pair: "USDT", volume: price.askSize * price.askPrice };
    }
    price = prices.find(item => item.symbol == coin + "BUSD");
    if (price != undefined && price.askPrice != 0) {
      return { price: price.askPrice, pair: "BUSD", volume: price.askSize * price.askPrice };
    }
    price = prices.find(item => item.symbol == coin + "BNB");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BNBUSDT");
      return { price: price.askPrice * multiply.bid, pair: "BNB", volume: price.askSize * price.askPrice * multiply.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "BTC");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BTCUSDT");
      return { price: price.askPrice * multiply.bid, pair: "BTC", volume: price.askSize * price.askPrice * multiply.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "ETH");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "ETHUSDT");
      return { price: price.askPrice * multiply.bid, pair: "ETH", volume: price.askSize * price.askPrice * multiply.bidPrice };
    }
    return { price: 0, pair: "NONE" };
  },
  getFtxBookCoinPriceBid: state => coin => {
    let prices = state.bookTicker.filter(
      item => item.symbol == coin + "USD" || item.symbol == coin + "USDT" || item.symbol == coin + "BUSD" || item.symbol == coin + "BNB" || item.symbol == coin + "BTC" || item.symbol == coin + "ETH",
    );
    let price;
    price = prices.find(item => item.symbol == coin + "USD");
    if (price != undefined && price.bidPrice != 0) {
      return { price: price.bidPrice, pair: "USD", volume: price.bidSize * price.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "USDT");
    if (price != undefined && price.bidPrice != 0) {
      return { price: price.bidPrice, pair: "USDT", volume: price.bidSize * price.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "BUSD");
    if (price != undefined && price.bidPrice != 0) {
      return { price: price.bidPrice, pair: "BUSD", volume: price.bidSize * price.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "BNB");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BNBUSDT");
      return { price: price.bidPrice * multiply.bidPrice, pair: "BNB", volume: price.bidSize * price.bidPrice * multiply.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "BTC");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BTCUSDT");
      return { price: price.bidPrice * multiply.bidPrice, pair: "BTC", volume: price.bidSize * price.bidPrice * multiply.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "ETH");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "ETHUSDT");
      return { price: price.bidPrice * multiply.bid, pair: "ETH", volume: price.bidSize * price.bidPrice * multiply.bidPrice };
    }
    return { price: 0, pair: "NONE" };
  },
};

const actions = {
  updateFtxBookTicker(context) {
    return axios
      .get("https://ftx.com/api/markets")
      .then(response => {
        let bookTicker = response.data.result;
        if (bookTicker[0] != undefined && bookTicker[0].name != undefined) {
          let fbookTicker = bookTicker
            .filter(item => item.name.indexOf("/") !== -1)
            .map(part => {
              return {
                symbol: part.name.toUpperCase().replace("/", ""),
                askPrice: part.ask,
                bidPrice: part.bid,
              };
            });
          localStorage.setItem("mw_ftx", JSON.stringify({ bookTicker: fbookTicker, lastFetch: Date.now() }));
          context.commit("updateFtxBookTicker", fbookTicker);
          return {
            status: "ok",
          };
        } else {
          return {
            status: "error",
            message: "Format respon ftx tidak valid",
          };
        }
      })
      .catch(error => {
        if (error.response) {
          return {
            status: "error",
            message: error.response.status,
          };
        } else if (error.request) {
          return {
            status: "error",
            message: "Tidak bisa menghubungi ftx",
          };
        } else {
          return {
            status: "error",
            message: error.message,
          };
        }
      });
  },
};

const mutations = {
  updateFtxBookTicker(state, payload) {
    state.bookTicker = payload;
    state.lastFetch = Date.now();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
