import axios from "axios";

let lsOkx = JSON.parse(localStorage.getItem("mw_okx"));

const state = {
  bookTicker: lsOkx != null && lsOkx.bookTicker != null ? lsOkx.bookTicker : [],
  lastFetch: lsOkx != null && lsOkx.lastFetch != null ? lsOkx.lastFetch : null,
};

const getters = {
  listOkxBookTicker(state) {
    return state.bookTicker;
  },
  getOkxBookCoinPriceAsk: state => coin => {
    let prices = state.bookTicker.filter(
      item => item.symbol == coin + "USDT" || item.symbol == coin + "BUSD" || item.symbol == coin + "BNB" || item.symbol == coin + "BTC" || item.symbol == coin + "ETH",
    );
    let price;
    price = prices.find(item => item.symbol == coin + "USDT");
    if (price != undefined && price.askPrice != 0) {
      return { price: price.askPrice, pair: "USDT", volume: price.askSize * price.askPrice };
    }
    price = prices.find(item => item.symbol == coin + "BUSD");
    if (price != undefined && price.askPrice != 0) {
      return { price: price.askPrice, pair: "BUSD", volume: price.askSize * price.askPrice };
    }
    price = prices.find(item => item.symbol == coin + "BNB");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BNBUSDT");
      return { price: price.askPrice * multiply.bidPrice, pair: "BNB", volume: price.askSize * price.askPrice * multiply.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "BTC");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BTCUSDT");
      return { price: price.askPrice * multiply.bidPrice, pair: "BTC", volume: price.askSize * price.askPrice * multiply.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "ETH");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "ETHUSDT");
      return { price: price.askPrice * multiply.bidPrice, pair: "ETH", volume: price.askSize * price.askPrice * multiply.bidPrice };
    }
    return { price: 0, pair: "NONE" };
  },
  getOkxBookCoinPriceBid: state => coin => {
    let prices = state.bookTicker.filter(
      item => item.symbol == coin + "USDT" || item.symbol == coin + "BUSD" || item.symbol == coin + "BNB" || item.symbol == coin + "BTC" || item.symbol == coin + "ETH",
    );
    let price;
    price = prices.find(item => item.symbol == coin + "USDT");
    if (price != undefined && price.bidPrice != 0) {
      return { price: price.bidPrice, pair: "USDT", volume: price.bidSize * price.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "BUSD");
    if (price != undefined && price.bidPrice != 0) {
      return { price: price.bidPrice, pair: "BUSD", volume: price.bidSize * price.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "BNB");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BNBUSDT");
      return { price: price.bidPrice * multiply.bidPrice, pair: "BNB", volume: price.bidSize * price.bidPrice * multiply.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "BTC");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BTCUSDT");
      return { price: price.bidPrice * multiply.bidPrice, pair: "BTC", volume: price.bidSize * price.bidPrice * multiply.bidPrice };
    }
    price = prices.find(item => item.symbol == coin + "ETH");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "ETHUSDT");
      return { price: price.bidPrice * multiply.bidPrice, pair: "ETH", volume: price.bidSize * price.bidPrice * multiply.bidPrice };
    }
    return { price: 0, pair: "NONE" };
  },
};

const actions = {
  updateOkxBookTicker(context) {
    return axios
      .get("https://www.okx.com/api/v5/market/tickers?instType=SPOT")
      .then(response => {
        let bookTicker = response.data.data;
        if (bookTicker[0] != undefined && bookTicker[0].instId != undefined) {
          let fbookTicker = bookTicker.map(part => {
            return {
              symbol: part.instId.toUpperCase().replace("-", ""),
              askPrice: part.askPx,
              bidPrice: part.bidPx,
              askSize: part.askSz,
              bidSize: part.bidSz,
            };
          });
          localStorage.setItem("mw_okx", JSON.stringify({ bookTicker: fbookTicker, lastFetch: Date.now() }));
          context.commit("updateOkxBookTicker", fbookTicker);
          return {
            status: "ok",
          };
        } else {
          return {
            status: "error",
            message: "Format respon okx tidak valid",
          };
        }
      })
      .catch(error => {
        if (error.response) {
          return {
            status: "error",
            message: error.response.status,
          };
        } else if (error.request) {
          return {
            status: "error",
            message: "Tidak bisa menghubungi okx",
          };
        } else {
          return {
            status: "error",
            message: error.message,
          };
        }
      });
  },
};

const mutations = {
  updateOkxBookTicker(state, payload) {
    state.bookTicker = payload;
    state.lastFetch = Date.now();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
