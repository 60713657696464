import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/dashboard_coba1",
          name: "dashboard_coba1",
          component: () => import("@/view/pages/Dashboard_coba1.vue"),
        },
        {
          path: "/dashboard_coba2",
          name: "dashboard_coba2",
          component: () => import("@/view/pages/Dashboard_coba2.vue"),
        },
        {
          path: "/dashboard2",
          name: "dashboard2",
          component: () => import("@/view/pages/Dashboard2.vue"),
        },
        {
          path: "/dashboard_erc",
          name: "dashboard_erc",
          component: () => import("@/view/pages/DashboardErc.vue"),
        },
        {
          path: "/dashboard_binance1inch_erc",
          name: "dashboard_binance1inch_erc",
          component: () => import("@/view/pages/DashboardBinance1InchErc.vue"),
        },
        {
          path: "/dashboard_matcha_bsc",
          name: "dashboard_matcha_bsc",
          component: () => import("@/view/pages/DashboardMatchaBsc.vue"),
        },
        {
          path: "/dashboard_hotbit_matcha_bsc",
          name: "dashboard_hotbit_matcha_bsc",
          component: () => import("@/view/pages/DashboardHotbitMatchaBsc.vue"),
        },
        {
          path: "/dashboard_hotbit_1inch_bsc",
          name: "dashboard_hotbit_1inch_bsc",
          component: () => import("@/view/pages/DashboardHotbit1InchBsc.vue"),
        },
        {
          path: "/dashboard_mexc_1inch_bsc",
          name: "dashboard_mexc_1inch_bsc",
          component: () => import("@/view/pages/DashboardMexc1InchBsc.vue"),
        },
        {
          path: "/dashboard_mexc_1inch_polygon",
          name: "dashboard_mexc_1inch_polygon",
          component: () => import("@/view/pages/DashboardMexc1InchPolygon.vue"),
        },
        {
          path: "/dashboard_mexc_matcha_bsc",
          name: "dashboard_mexc_matcha_bsc",
          component: () => import("@/view/pages/DashboardMexcMatchaBsc.vue"),
        },
        {
          path: "/dashboard_matcha_erc",
          name: "dashboard_matcha_erc",
          component: () => import("@/view/pages/DashboardMatchaErc.vue"),
        },
        {
          path: "/dashboard_para_bsc",
          name: "dashboard_para_bsc",
          component: () => import("@/view/pages/DashboardParaBsc.vue"),
        },
        {
          path: "/dashboard_dodo_bsc",
          name: "dashboard_dodo_bsc",
          component: () => import("@/view/pages/DashboardDodoBsc.vue"),
        },
        {
          path: "/dashboard_huobi1inch_erc",
          name: "dashboard_huobi1inch_erc",
          component: () => import("@/view/pages/DashboardHuobi1InchErc.vue"),
        },
        {
          path: "/dashboard_huobimatcha_bsc",
          name: "dashboard_huobimatcha_bsc",
          component: () => import("@/view/pages/DashboardHuobiMatchaBsc.vue"),
        },
        {
          path: "/dashboard_huobi1inch_bsc",
          name: "dashboard_huobi1inch_bsc",
          component: () => import("@/view/pages/DashboardHuobi1InchBsc.vue"),
        },
        {
          path: "/dashboard_huobidodo_erc",
          name: "dashboard_huobidodo_erc",
          component: () => import("@/view/pages/DashboardHuobiDodoErc.vue"),
        },
        {
          path: "/dashboard_huobi_dodo_heco",
          name: "dashboard_huobi_dodo_heco",
          component: () => import("@/view/pages/DashboardHuobiDodoHeco.vue"),
        },
        {
          path: "/dashboard_1inch_bnb_bsc",
          name: "dashboard_1inch_bnb_bsc",
          component: () => import("@/view/pages/Dashboard1InchBnbBsc.vue"),
        },
        {
          path: "/dashboard_1inch_btc_bsc",
          name: "dashboard_1inch_btc_bsc",
          component: () => import("@/view/pages/Dashboard1InchBtcBsc.vue"),
        },
        {
          path: "/dashboard_1inch_eth_erc",
          name: "dashboard_1inch_eth_erc",
          component: () => import("@/view/pages/Dashboard1InchEthErc.vue"),
        },
        {
          path: "/dashboard_matcha_bnb_bsc",
          name: "dashboard_matcha_bnb_bsc",
          component: () => import("@/view/pages/DashboardMatchaBnbBsc.vue"),
        },
        {
          path: "/dashboard_paraswap_bnb_bsc",
          name: "dashboard_paraswap_bnb_bsc",
          component: () => import("@/view/pages/DashboardParaswapBnbBsc.vue"),
        },
        {
          path: "/dashboard_kucoin1inch_bsc",
          name: "dashboard_kucoin1inch_bsc",
          component: () => import("@/view/pages/DashboardKucoin1InchBsc.vue"),
        },
        {
          path: "/dashboard_kucoin1inch_erc",
          name: "dashboard_kucoin1inch_erc",
          component: () => import("@/view/pages/DashboardKucoin1InchErc.vue"),
        },
        {
          path: "/dashboard_kucoin_multi_erc",
          name: "dashboard_kucoin_multi_erc",
          component: () => import("@/view/pages/DashboardKucoinMultiErc.vue"),
        },
        {
          path: "/dashboard_okx1inch_erc",
          name: "dashboard_okx1inch_erc",
          component: () => import("@/view/pages/DashboardOkx1InchErc.vue"),
        },
        {
          path: "/dashboard_ftx1inch_erc",
          name: "dashboard_ftx1inch_erc",
          component: () => import("@/view/pages/DashboardFtx1InchErc.vue"),
        },
        {
          path: "/dashboard_ftxmatcha_erc",
          name: "dashboard_ftxmatcha_erc",
          component: () => import("@/view/pages/DashboardFtxMatchaErc.vue"),
        },
        {
          path: "/user_manager",
          name: "user_manager",
          component: () => import("@/view/pages/user/UserManager.vue"),
        },
        {
          path: "/setting",
          name: "setting",
          component: () => import("@/view/pages/Setting.vue"),
        },
        {
          path: "/data",
          name: "data",
          component: () => import("@/view/pages/Data.vue"),
        },
        {
          path: "/debug",
          name: "debug",
          component: () => import("@/view/pages/Debug.vue"),
        },
        {
          path: "/modal",
          name: "modal",
          component: () => import("@/view/pages/Modal.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
    {
      // the 404 route, when none of the above matches
      path: "/restricted",
      name: "restricted",
      component: () => import("@/view/pages/error/ErrorRestricted.vue"),
    },
  ],
});
