//fix
if (window.localStorage.getItem("mw_coins") === "undefined") {
  window.localStorage.setItem("mw_coins", "[]");
}
if (window.localStorage.getItem("mw_coins_hotbit_bsc") === "undefined") {
  window.localStorage.setItem("mw_coins_hotbit_bsc", "[]");
}
if (window.localStorage.getItem("mw_coins_mexc_bsc") === "undefined") {
  window.localStorage.setItem("mw_coins_mexc_bsc", "[]");
}
if (window.localStorage.getItem("mw_coins_mexc_polygon") === "undefined") {
  window.localStorage.setItem("mw_coins_mexc_polygon", "[]");
}
if (window.localStorage.getItem("mw_coins_erc") === "undefined") {
  window.localStorage.setItem("mw_coins_erc", "[]");
}
if (window.localStorage.getItem("mw_coins_huobi_heco") === "undefined") {
  window.localStorage.setItem("mw_coins_huobi_heco", "[]");
}
if (window.localStorage.getItem("mw_coins_huobi_erc") === "undefined") {
  window.localStorage.setItem("mw_coins_huobi_erc", "[]");
}
if (window.localStorage.getItem("mw_coins_huobi_bsc") === "undefined") {
  window.localStorage.setItem("mw_coins_huobi_bsc", "[]");
}
if (window.localStorage.getItem("mw_coins_kucoin_bsc") === "undefined") {
  window.localStorage.setItem("mw_coins_kucoin_bsc", "[]");
}
if (window.localStorage.getItem("mw_coins_kucoin_erc") === "undefined") {
  window.localStorage.setItem("mw_coins_kucoin_erc", "[]");
}
if (window.localStorage.getItem("mw_coins_okx_erc") === "undefined") {
  window.localStorage.setItem("mw_coins_okx_erc", "[]");
}
if (window.localStorage.getItem("mw_coins_ftx_erc") === "undefined") {
  window.localStorage.setItem("mw_coins_ftx_erc", "[]");
}
const state = {
  coins: JSON.parse(localStorage.getItem("mw_coins")),
  coins_hotbit_bsc: JSON.parse(localStorage.getItem("mw_coins_hotbit_bsc")),
  coins_mexc_bsc: JSON.parse(localStorage.getItem("mw_coins_mexc_bsc")),
  coins_mexc_polygon: JSON.parse(localStorage.getItem("mw_coins_mexc_polygon")),
  coins_erc: JSON.parse(localStorage.getItem("mw_coins_erc")),
  coins_huobi_heco: JSON.parse(localStorage.getItem("mw_coins_huobi_heco")),
  coins_huobi_erc: JSON.parse(localStorage.getItem("mw_coins_huobi_erc")),
  coins_huobi_bsc: JSON.parse(localStorage.getItem("mw_coins_huobi_bsc")),
  coins_kucoin_bsc: JSON.parse(localStorage.getItem("mw_coins_kucoin_bsc")),
  coins_kucoin_erc: JSON.parse(localStorage.getItem("mw_coins_kucoin_erc")),
  coins_okx_erc: JSON.parse(localStorage.getItem("mw_coins_okx_erc")),
  coins_ftx_erc: JSON.parse(localStorage.getItem("mw_coins_ftx_erc")),
};

const getters = {
  listCoins(state) {
    return state.coins;
  },
  listCoins_hotbit_bsc(state) {
    return state.coins_hotbit_bsc;
  },
  listCoins_mexc_bsc(state) {
    return state.coins_mexc_bsc;
  },
  listCoins_mexc_polygon(state) {
    return state.coins_mexc_polygon;
  },
  listCoins_erc(state) {
    return state.coins_erc;
  },
  listCoins_heco(state) {
    return state.coins_heco;
  },
  listCoins_matcha_erc(state) {
    return state.coins_matcha_erc;
  },
  listCoins_huobi_heco(state) {
    return state.coins_huobi_heco;
  },
  listCoins_huobi_erc(state) {
    return state.coins_huobi_erc;
  },
  listCoins_huobi_bsc(state) {
    return state.coins_huobi_bsc;
  },
  listCoins_kucoin_bsc(state) {
    return state.coins_kucoin_bsc;
  },
  listCoins_kucoin_erc(state) {
    return state.coins_kucoin_erc;
  },
  listCoins_okx_erc(state) {
    return state.coins_okx_erc;
  },
  listCoins_ftx_erc(state) {
    return state.coins_ftx_erc;
  },
};

const actions = {
  saveCoin(context, payload) {
    //cek duplikat
    let coins = state.coins;
    if (!Array.isArray(coins)) {
      coins = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins.push(data);
    } else {
      //update
      coins.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins", JSON.stringify(coins));
    context.commit("setCoins", coins);
  },
  updateAktifCoin(context, payload) {
    //cek duplikat
    let coins = state.coins;
    if (!Array.isArray(coins)) {
      coins = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins.push(data);
    } else {
      //update
      coins.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins", JSON.stringify(coins));
    context.commit("setCoins", coins);
  },
  updateAktifCoinErc(context, payload) {
    //cek duplikat
    let coins = state.coins_erc;
    if (!Array.isArray(coins)) {
      coins = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins.push(data);
    } else {
      //update
      coins.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_erc", JSON.stringify(coins));
    context.commit("setCoins_erc", coins);
  },
  updateAktifCoinMexcBsc(context, payload) {
    //cek duplikat
    let coins_mexc_bsc = state.coins_mexc_bsc;
    if (!Array.isArray(coins_mexc_bsc)) {
      coins_mexc_bsc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_mexc_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins_mexc_bsc[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins_mexc_bsc.push(data);
    } else {
      //update
      coins_mexc_bsc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_mexc_bsc", JSON.stringify(coins_mexc_bsc));
    context.commit("setCoins", coins_mexc_bsc);
  },
  updateAktifCoinMexcPolygon(context, payload) {
    //cek duplikat
    let coins_mexc_polygon = state.coins_mexc_polygon;
    if (!Array.isArray(coins_mexc_polygon)) {
      coins_mexc_polygon = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_mexc_polygon.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins_mexc_polygon[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins_mexc_polygon.push(data);
    } else {
      //update
      coins_mexc_polygon.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_mexc_polygon", JSON.stringify(coins_mexc_polygon));
    context.commit("setCoins", coins_mexc_polygon);
  },
  updateAktifCoinHuobiErc(context, payload) {
    //cek duplikat
    let coins_huobi_erc = state.coins_huobi_erc;
    if (!Array.isArray(coins_huobi_erc)) {
      coins_huobi_erc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_huobi_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins_huobi_erc[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins_huobi_erc.push(data);
    } else {
      //update
      coins_huobi_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_huobi_erc", JSON.stringify(coins_huobi_erc));
    context.commit("setCoins", coins_huobi_erc);
  },
  updateAktifCoinHuobiBsc(context, payload) {
    //cek duplikat
    let coins_huobi_bsc = state.coins_huobi_bsc;
    if (!Array.isArray(coins_huobi_bsc)) {
      coins_huobi_bsc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_huobi_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins_huobi_bsc[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins_huobi_bsc.push(data);
    } else {
      //update
      coins_huobi_bsc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_huobi_bsc", JSON.stringify(coins_huobi_bsc));
    context.commit("setCoins", coins_huobi_bsc);
  },
  updateAktifCoinKucoinBsc(context, payload) {
    //cek duplikat
    let coins_kucoin_bsc = state.coins_kucoin_bsc;
    if (!Array.isArray(coins_kucoin_bsc)) {
      coins_kucoin_bsc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_kucoin_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins_kucoin_bsc[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins_kucoin_bsc.push(data);
    } else {
      //update
      coins_kucoin_bsc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_kucoin_bsc", JSON.stringify(coins_kucoin_bsc));
    context.commit("setCoins", coins_kucoin_bsc);
  },
  updateAktifCoinKucoinErc(context, payload) {
    //cek duplikat
    let coins_kucoin_erc = state.coins_kucoin_erc;
    if (!Array.isArray(coins_kucoin_erc)) {
      coins_kucoin_erc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_kucoin_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins_kucoin_erc[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins_kucoin_erc.push(data);
    } else {
      //update
      coins_kucoin_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_kucoin_erc", JSON.stringify(coins_kucoin_erc));
    context.commit("setCoins", coins_kucoin_erc);
  },
  updateAktifCoinOkxErc(context, payload) {
    //cek duplikat
    let coins_okx_erc = state.coins_okx_erc;
    if (!Array.isArray(coins_okx_erc)) {
      coins_okx_erc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_okx_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins_okx_erc[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins_okx_erc.push(data);
    } else {
      //update
      coins_okx_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_okx_erc", JSON.stringify(coins_okx_erc));
    context.commit("setCoins", coins_okx_erc);
  },
  updateAktifCoinFtxErc(context, payload) {
    //cek duplikat
    let coins_ftx_erc = state.coins_ftx_erc;
    if (!Array.isArray(coins_ftx_erc)) {
      coins_ftx_erc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_ftx_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins_ftx_erc[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins_ftx_erc.push(data);
    } else {
      //update
      coins_ftx_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_ftx_erc", JSON.stringify(coins_ftx_erc));
    context.commit("setCoins", coins_ftx_erc);
  },
  deleteCoin(context, payload) {
    let coins = state.coins;
    if (!Array.isArray(coins)) {
      coins = [];
    }
    let search = payload;
    let del_target = coins.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins", JSON.stringify(coins));
    context.commit("setCoins", coins);
  },
  importCoin(context, payload) {
    localStorage.setItem("mw_coins", JSON.stringify(payload.coin));
    localStorage.setItem("mw_coins_hotbit_bsc", JSON.stringify(payload.coin_hotbit_bsc));
    localStorage.setItem("mw_coins_mexc_bsc", JSON.stringify(payload.coin_mexc_bsc));
    localStorage.setItem("mw_coins_mexc_polygon", JSON.stringify(payload.coin_mexc_polygon));
    localStorage.setItem("mw_coins_erc", JSON.stringify(payload.coin_erc));
    localStorage.setItem("mw_coins_huobi_heco", JSON.stringify(payload.coin_huobi_heco));
    localStorage.setItem("mw_coins_huobi_erc", JSON.stringify(payload.coin_huobi_erc));
    localStorage.setItem("mw_coins_huobi_bsc", JSON.stringify(payload.coin_huobi_bsc));
    localStorage.setItem("mw_coins_kucoin_bsc", JSON.stringify(payload.coin_kucoin_bsc));
    localStorage.setItem("mw_coins_kucoin_erc", JSON.stringify(payload.coin_kucoin_erc));
    localStorage.setItem("mw_coins_okx_erc", JSON.stringify(payload.coin_okx_erc));
    localStorage.setItem("mw_coins_ftx_erc", JSON.stringify(payload.coin_ftx_erc));
    context.commit("setCoins", payload.coin);
    context.commit("setCoins_hotbit_bsc", payload.coin_hotbit_bsc);
    context.commit("setCoins_mexc_bsc", payload.coin_mexc_bsc);
    context.commit("setCoins_mexc_polygon", payload.coin_mexc_polygon);
    context.commit("setCoins_erc", payload.coin_erc);
    context.commit("setCoins_huobi_heco", payload.coin_huobi_heco);
    context.commit("setCoins_huobi_erc", payload.coin_huobi_erc);
    context.commit("setCoins_huobi_bsc", payload.coin_huobi_bsc);
    context.commit("setCoins_kucoin_bsc", payload.coin_kucoin_bsc);
    context.commit("setCoins_kucoin_erc", payload.coin_kucoin_erc);
    context.commit("setCoins_okx_erc", payload.coin_okx_erc);
    context.commit("setCoins_ftx_erc", payload.coin_ftx_erc);
  },
  saveCoin_hotbit_bsc(context, payload) {
    //cek duplikat
    let coins_hotbit_bsc = state.coins_hotbit_bsc;
    if (!Array.isArray(coins_hotbit_bsc)) {
      coins_hotbit_bsc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_hotbit_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_hotbit_bsc.push(data);
    } else {
      //update
      coins_hotbit_bsc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_hotbit_bsc", JSON.stringify(coins_hotbit_bsc));
    context.commit("setCoins_hotbit_bsc", coins_hotbit_bsc);
  },
  deleteCoin_hotbit_bsc(context, payload) {
    let coins_hotbit_bsc = state.coins_hotbit_bsc;
    if (!Array.isArray(coins_hotbit_bsc)) {
      coins_hotbit_bsc = [];
    }
    let search = payload;
    let del_target = coins_hotbit_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_hotbit_bsc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_hotbit_bsc", JSON.stringify(coins_hotbit_bsc));
    context.commit("setCoins_hotbit_bsc", coins_hotbit_bsc);
  },
  saveCoin_mexc_bsc(context, payload) {
    //cek duplikat
    let coins_mexc_bsc = state.coins_mexc_bsc;
    if (!Array.isArray(coins_mexc_bsc)) {
      coins_mexc_bsc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_mexc_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_mexc_bsc.push(data);
    } else {
      //update
      coins_mexc_bsc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_mexc_bsc", JSON.stringify(coins_mexc_bsc));
    context.commit("setCoins_mexc_bsc", coins_mexc_bsc);
  },
  deleteCoin_mexc_bsc(context, payload) {
    let coins_mexc_bsc = state.coins_mexc_bsc;
    if (!Array.isArray(coins_mexc_bsc)) {
      coins_mexc_bsc = [];
    }
    let search = payload;
    let del_target = coins_mexc_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_mexc_bsc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_mexc_bsc", JSON.stringify(coins_mexc_bsc));
    context.commit("setCoins_mexc_bsc", coins_mexc_bsc);
  },
  saveCoin_mexc_polygon(context, payload) {
    //cek duplikat
    let coins_mexc_polygon = state.coins_mexc_polygon;
    if (!Array.isArray(coins_mexc_polygon)) {
      coins_mexc_polygon = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_mexc_polygon.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_mexc_polygon.push(data);
    } else {
      //update
      coins_mexc_polygon.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_mexc_polygon", JSON.stringify(coins_mexc_polygon));
    context.commit("setCoins_mexc_polygon", coins_mexc_polygon);
  },
  deleteCoin_mexc_polygon(context, payload) {
    let coins_mexc_polygon = state.coins_mexc_polygon;
    if (!Array.isArray(coins_mexc_polygon)) {
      coins_mexc_polygon = [];
    }
    let search = payload;
    let del_target = coins_mexc_polygon.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_mexc_polygon.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_mexc_polygon", JSON.stringify(coins_mexc_polygon));
    context.commit("setCoins_mexc_polygon", coins_mexc_polygon);
  },
  saveCoin_erc(context, payload) {
    //cek duplikat
    let coins_erc = state.coins_erc;
    if (!Array.isArray(coins_erc)) {
      coins_erc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_erc.push(data);
    } else {
      //update
      coins_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_erc", JSON.stringify(coins_erc));
    context.commit("setCoins_erc", coins_erc);
  },
  deleteCoin_erc(context, payload) {
    let coins_erc = state.coins_erc;
    if (!Array.isArray(coins_erc)) {
      coins_erc = [];
    }
    let search = payload;
    let del_target = coins_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_erc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_erc", JSON.stringify(coins_erc));
    context.commit("setCoins_erc", coins_erc);
  },
  saveCoin_huobi_heco(context, payload) {
    //cek duplikat
    let coins_huobi_heco = state.coins_huobi_heco;
    if (!Array.isArray(coins_huobi_heco)) {
      coins_huobi_heco = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_huobi_heco.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_huobi_heco.push(data);
    } else {
      //update
      coins_huobi_heco.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_huobi_heco", JSON.stringify(coins_huobi_heco));
    context.commit("setCoins_huobi_heco", coins_huobi_heco);
  },
  deleteCoin_huobi_heco(context, payload) {
    let coins_huobi_heco = state.coins_huobi_heco;
    if (!Array.isArray(coins_huobi_heco)) {
      coins_huobi_heco = [];
    }
    let search = payload;
    let del_target = coins_huobi_heco.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_huobi_heco.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_huobi_heco", JSON.stringify(coins_huobi_heco));
    context.commit("setCoins_huobi_heco", coins_huobi_heco);
  },
  saveCoin_huobi_erc(context, payload) {
    //cek duplikat
    let coins_huobi_erc = state.coins_huobi_erc;
    if (!Array.isArray(coins_huobi_erc)) {
      coins_huobi_erc = [];
    }
    console.log("asdf", coins_huobi_erc);
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_huobi_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_huobi_erc.push(data);
    } else {
      //update
      coins_huobi_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_huobi_erc", JSON.stringify(coins_huobi_erc));
    context.commit("setCoins_huobi_erc", coins_huobi_erc);
  },
  deleteCoin_huobi_erc(context, payload) {
    let coins_huobi_erc = state.coins_huobi_erc;
    if (!Array.isArray(coins_huobi_erc)) {
      coins_huobi_erc = [];
    }
    let search = payload;
    let del_target = coins_huobi_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_huobi_erc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_huobi_erc", JSON.stringify(coins_huobi_erc));
    context.commit("setCoins_huobi_erc", coins_huobi_erc);
  },
  saveCoin_huobi_bsc(context, payload) {
    //cek duplikat
    let coins_huobi_bsc = state.coins_huobi_bsc;
    if (!Array.isArray(coins_huobi_bsc)) {
      coins_huobi_bsc = [];
    }
    console.log("asdf", coins_huobi_bsc);
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_huobi_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_huobi_bsc.push(data);
    } else {
      //update
      coins_huobi_bsc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_huobi_bsc", JSON.stringify(coins_huobi_bsc));
    context.commit("setCoins_huobi_bsc", coins_huobi_bsc);
  },
  deleteCoin_huobi_bsc(context, payload) {
    let coins_huobi_bsc = state.coins_huobi_bsc;
    if (!Array.isArray(coins_huobi_bsc)) {
      coins_huobi_bsc = [];
    }
    let search = payload;
    let del_target = coins_huobi_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_huobi_bsc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_huobi_bsc", JSON.stringify(coins_huobi_bsc));
    context.commit("setCoins_huobi_bsc", coins_huobi_bsc);
  },
  saveCoin_matcha_erc(context, payload) {
    //cek duplikat
    let coins_matcha_erc = state.coins_matcha_erc;
    if (!Array.isArray(coins_matcha_erc)) {
      coins_matcha_erc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_matcha_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_matcha_erc.push(data);
    } else {
      //update
      coins_matcha_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_matcha_erc", JSON.stringify(coins_matcha_erc));
    context.commit("setCoins_matcha_erc", coins_matcha_erc);
  },
  deleteCoin_matcha_erc(context, payload) {
    let coins_matcha_erc = state.coins_matcha_erc;
    if (!Array.isArray(coins_matcha_erc)) {
      coins_matcha_erc = [];
    }
    let search = payload;
    let del_target = coins_matcha_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_matcha_erc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_matcha_erc", JSON.stringify(coins_matcha_erc));
    context.commit("setCoins_matcha_erc", coins_matcha_erc);
  },
  saveCoin_kucoin_bsc(context, payload) {
    //cek duplikat
    let coins_kucoin_bsc = state.coins_kucoin_bsc;
    if (!Array.isArray(coins_kucoin_bsc)) {
      coins_kucoin_bsc = [];
    }
    console.log("asdf", coins_kucoin_bsc);
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_kucoin_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_kucoin_bsc.push(data);
    } else {
      //update
      coins_kucoin_bsc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_kucoin_bsc", JSON.stringify(coins_kucoin_bsc));
    context.commit("setCoins_kucoin_bsc", coins_kucoin_bsc);
  },
  deleteCoin_kucoin_bsc(context, payload) {
    let coins_kucoin_bsc = state.coins_kucoin_bsc;
    if (!Array.isArray(coins_kucoin_bsc)) {
      coins_kucoin_bsc = [];
    }
    let search = payload;
    let del_target = coins_kucoin_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_kucoin_bsc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_kucoin_bsc", JSON.stringify(coins_kucoin_bsc));
    context.commit("setCoins_kucoin_bsc", coins_kucoin_bsc);
  },
  saveCoin_kucoin_erc(context, payload) {
    //cek duplikat
    let coins_kucoin_erc = state.coins_kucoin_erc;
    if (!Array.isArray(coins_kucoin_erc)) {
      coins_kucoin_erc = [];
    }
    console.log("asdf", coins_kucoin_erc);
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_kucoin_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_kucoin_erc.push(data);
    } else {
      //update
      coins_kucoin_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_kucoin_erc", JSON.stringify(coins_kucoin_erc));
    context.commit("setCoins_kucoin_erc", coins_kucoin_erc);
  },
  deleteCoin_kucoin_erc(context, payload) {
    let coins_kucoin_erc = state.coins_kucoin_erc;
    if (!Array.isArray(coins_kucoin_erc)) {
      coins_kucoin_erc = [];
    }
    let search = payload;
    let del_target = coins_kucoin_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_kucoin_erc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_kucoin_erc", JSON.stringify(coins_kucoin_erc));
    context.commit("setCoins_kucoin_erc", coins_kucoin_erc);
  },
  saveCoin_okx_erc(context, payload) {
    //cek duplikat
    let coins_okx_erc = state.coins_okx_erc;
    if (!Array.isArray(coins_okx_erc)) {
      coins_okx_erc = [];
    }
    console.log("asdf", coins_okx_erc);
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_okx_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_okx_erc.push(data);
    } else {
      //update
      coins_okx_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_okx_erc", JSON.stringify(coins_okx_erc));
    context.commit("setCoins_okx_erc", coins_okx_erc);
  },
  deleteCoin_okx_erc(context, payload) {
    let coins_okx_erc = state.coins_okx_erc;
    if (!Array.isArray(coins_okx_erc)) {
      coins_okx_erc = [];
    }
    let search = payload;
    let del_target = coins_okx_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_okx_erc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_okx_erc", JSON.stringify(coins_okx_erc));
    context.commit("setCoins_okx_erc", coins_okx_erc);
  },
  saveCoin_ftx_erc(context, payload) {
    //cek duplikat
    let coins_ftx_erc = state.coins_ftx_erc;
    if (!Array.isArray(coins_ftx_erc)) {
      coins_ftx_erc = [];
    }
    console.log("asdf", coins_ftx_erc);
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_ftx_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      offset_pnl: payload.offset_pnl,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_ftx_erc.push(data);
    } else {
      //update
      coins_ftx_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_ftx_erc", JSON.stringify(coins_ftx_erc));
    context.commit("setCoins_ftx_erc", coins_ftx_erc);
  },
  deleteCoin_ftx_erc(context, payload) {
    let coins_ftx_erc = state.coins_ftx_erc;
    if (!Array.isArray(coins_ftx_erc)) {
      coins_ftx_erc = [];
    }
    let search = payload;
    let del_target = coins_ftx_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_ftx_erc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_ftx_erc", JSON.stringify(coins_ftx_erc));
    context.commit("setCoins_ftx_erc", coins_ftx_erc);
  },
};

const mutations = {
  setCoins(state, coins) {
    state.coins = coins;
  },
  setCoins_hotbit_bsc(state, coins_hotbit_bsc) {
    state.coins_hotbit_bsc = coins_hotbit_bsc;
  },
  setCoins_mexc_bsc(state, coins_mexc_bsc) {
    state.coins_mexc_bsc = coins_mexc_bsc;
  },
  setCoins_mexc_polygon(state, coins_mexc_polygon) {
    state.coins_mexc_polygon = coins_mexc_polygon;
  },
  setCoins_erc(state, coins_erc) {
    state.coins_erc = coins_erc;
  },
  setCoins_huobi_heco(state, coins_huobi_heco) {
    state.coins_huobi_heco = coins_huobi_heco;
  },
  setCoins_huobi_erc(state, coins_huobi_erc) {
    state.coins_huobi_erc = coins_huobi_erc;
  },
  setCoins_huobi_bsc(state, coins_huobi_bsc) {
    state.coins_huobi_bsc = coins_huobi_bsc;
  },
  setCoins_matcha_erc(state, coins_matcha_erc) {
    state.coins_matcha_erc = coins_matcha_erc;
  },
  setCoins_kucoin_bsc(state, coins_kucoin_bsc) {
    state.coins_kucoin_bsc = coins_kucoin_bsc;
  },
  setCoins_kucoin_erc(state, coins_kucoin_erc) {
    state.coins_kucoin_erc = coins_kucoin_erc;
  },
  setCoins_okx_erc(state, coins_okx_erc) {
    state.coins_okx_erc = coins_okx_erc;
  },
  setCoins_ftx_erc(state, coins_ftx_erc) {
    state.coins_ftx_erc = coins_ftx_erc;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
