import axios from "axios";

let lsMexc = JSON.parse(localStorage.getItem("mw_mxc"));

const state = {
  bookTicker: lsMexc != null && lsMexc.bookTicker != null ? lsMexc.bookTicker : [],
  lastFetch: lsMexc != null && lsMexc.lastFetch != null ? lsMexc.lastFetch : null,
};

const getters = {
  listMexcBookTicker(state) {
    return state.bookTicker;
  },
  getMexcBookCoinPriceAsk: state => coin => {
    let prices = state.bookTicker.filter(
      item => item.symbol == coin + "USDT" || item.symbol == coin + "BUSD" || item.symbol == coin + "BNB" || item.symbol == coin + "BTC" || item.symbol == coin + "ETH",
    );
    let price;
    price = prices.find(item => item.symbol == coin + "USDT");
    if (price != undefined && price.askPrice != 0) {
      return { price: price.askPrice, pair: "USDT", qty: price.askQty };
    }
    price = prices.find(item => item.symbol == coin + "BUSD");
    if (price != undefined && price.askPrice != 0) {
      return { price: price.askPrice, pair: "BUSD", qty: price.askQty };
    }
    price = prices.find(item => item.symbol == coin + "BNB");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BNBUSDT");
      return { price: price.askPrice * multiply.bid, pair: "BNB", xprice: price.askPrice, pairPrice: multiply.ask, qty: price.askQty };
    }
    price = prices.find(item => item.symbol == coin + "BTC");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BTCUSDT");
      return { price: price.askPrice * multiply.bid, pair: "BTC", xprice: price.askPrice, pairPrice: multiply.ask, qty: price.askQty };
    }
    price = prices.find(item => item.symbol == coin + "ETH");
    if (price != undefined && price.askPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "ETHUSDT");
      return { price: price.askPrice * multiply.bid, pair: "ETH", xprice: price.askPrice, pairPrice: multiply.ask, qty: price.askQty };
    }
    return { price: 0, pair: "NONE" };
  },
  getMexcBookCoinPriceBid: state => coin => {
    let prices = state.bookTicker.filter(
      item => item.symbol == coin + "USDT" || item.symbol == coin + "BUSD" || item.symbol == coin + "BNB" || item.symbol == coin + "BTC" || item.symbol == coin + "ETH",
    );
    let price;
    price = prices.find(item => item.symbol == coin + "USDT");
    if (price != undefined && price.bidPrice != 0) {
      return { price: price.bidPrice, pair: "USDT", qty: price.bidQty };
    }
    price = prices.find(item => item.symbol == coin + "BUSD");
    if (price != undefined && price.bidPrice != 0) {
      return { price: price.bidPrice, pair: "BUSD", qty: price.bidQty };
    }
    price = prices.find(item => item.symbol == coin + "BNB");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BNBUSDT");
      return { price: price.bidPrice * multiply.bid, pair: "BNB", xprice: price.bidPrice, pairPrice: multiply.bid, qty: price.bidQty };
    }
    price = prices.find(item => item.symbol == coin + "BTC");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "BTCUSDT");
      return { price: price.bidPrice * multiply.bid, pair: "BTC", xprice: price.bidPrice, pairPrice: multiply.bid, qty: price.bidQty };
    }
    price = prices.find(item => item.symbol == coin + "ETH");
    if (price != undefined && price.bidPrice != 0) {
      let multiply = state.bookTicker.find(item => item.symbol == "ETHUSDT");
      return { price: price.bidPrice * multiply.bid, pair: "ETH", xprice: price.bidPrice, pairPrice: multiply.bid, qty: price.bidQty };
    }
    return { price: 0, pair: "NONE" };
  },
};

const actions = {
  updateMexcBookTicker(context) {
    return axios
      .get("https://api.mexc.com/api/v3/ticker/bookTicker")
      .then(response => {
        let bookTicker = response.data;
        console.log("SSS", bookTicker);
        if (bookTicker[0] != undefined && bookTicker[0].symbol != undefined) {
          localStorage.setItem("mw_mxc", JSON.stringify({ bookTicker, lastFetch: Date.now() }));
          context.commit("updateMexcBookTicker", bookTicker);
          return {
            status: "ok",
          };
        } else {
          return {
            status: "error",
            message: "Format respon mexc tidak valid",
          };
        }
      })
      .catch(error => {
        if (error.response) {
          return {
            status: "error",
            message: error.response.status,
          };
        } else if (error.request) {
          return {
            status: "error",
            message: "Tidak bisa menghubungi mexc",
          };
        } else {
          return {
            status: "error",
            message: error.message,
          };
        }
      });
  },
};

const mutations = {
  updateMexcBookTicker(state, payload) {
    state.bookTicker = payload;
    state.lastFetch = Date.now();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
